import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
};

const MasterData = {
	SettingAccess: lazy(() => import('../views/afif/settingAccess')),
};

const LMS = {
	ModuleTraining: lazy(() => import('../views/sodiq/ModuleTraining')),
	TrainingPlan: lazy(() => import('../views/handri/TrainingPlan')),
	TrainingSchedule: lazy(() => import('../views/handri/TrainingSchedule')),
	Training: lazy(() => import('../views/handri/Training')),
	ListTrainingHCP: lazy(() => import('../views/sodiq/ListTrainingHCP')),
	TrainingPage: lazy(() => import('../views/handri/Training/TrainingPage')),
	TrainingReport: lazy(() => import('../views/bakti/training-report/TrainingReport')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <LMS.ModuleTraining />,
		index: 'ModuleTraining',
		exact: true,
	},
	{
		path: null,
		element: <LMS.TrainingPlan />,
		index: 'TrainingPlan',
		exact: true,
	},
	{
		path: null,
		element: <LMS.TrainingSchedule />,
		index: 'TrainingSchedule',
		exact: true,
	},
	{
		path: null,
		element: <LMS.Training />,
		index: 'ListTraining',
		exact: true,
	},
	{
		path: null,
		element: <LMS.ListTrainingHCP />,
		index: 'ListTrainingHCP',
		exact: true,
	},
	{
		path: '/list-training/training-page/:id',
		element: <LMS.TrainingPage />,
		index: 'TrainingPage',
		exact: true,
	},
	{
		path: '/training-report',
		element: <LMS.TrainingReport />,
		index: 'TrainingReport',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.SettingAccess />,
		index: 'SettingAccess',
		exact: true,
	},
];
export default contents;
