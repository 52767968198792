import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Card, { CardBody, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Preview from './Preview';
import InfoTraining from './InfoTraining';
import useDarkMode from '../../../hooks/useDarkMode';
import Badge from '../../../components/bootstrap/Badge';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Page from '../../../layout/Page/Page';
import RealizationTrainingModule from '../../../modules/handri/RealizationTrainingModule';
import { getRequester } from '../../../helpers/helpers';

const format = {
	Status: (status) => {
		const { darkModeStatus } = useDarkMode();
		let color = '';
		// eslint-disable-next-line react/destructuring-assignment
		let label = '';
		if (status === 'upcoming') {
			color = 'info';
			label = 'Upcoming';
		} else if (status === 'ongoing') {
			color = 'success';
			label = 'Ongoing';
		} else if (status === 'completed') {
			color = 'primary';
			label = 'Completed';
		} else if (status === 'expired') {
			color = 'danger';
			label = 'Missed';
		} else {
			color = '';
		}

		if (color !== '') {
			return (
				<Badge isLight={darkModeStatus} rounded='pill' color={color}>
					{label}
				</Badge>
			);
		}
		return '';
	},
};

const TrainingPage = () => {
	const [itemDetail, setItemDetail] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();

	const generateRealization = async (schedule_training_id) => {
		const { username: nik, person_name: name_participant } = getRequester();
		const values = {
			id: schedule_training_id,
			username: nik,
			name: name_participant,
		};
		return RealizationTrainingModule.create(values)
			.then((res) => {
				const newRest = { ...res, trainer: res.schedule_training.trainer };
				setItemDetail(newRest);
			})
			.catch((err) => {
				Swal.fire('Warning!', err, 'error');
			});
	};

	useEffect(() => {
		generateRealization(id);

		//	eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper title='Learning List'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='mx-4'>
					<div className='row'>
						<Card>
							<CardBody>
								<Button
									color='info'
									icon='ArrowBack'
									isLink
									onClick={() => navigate(-1)}>
									Back
								</Button>
							</CardBody>
						</Card>
					</div>
					{itemDetail && (
						<div className='row'>
							<Card>
								<CardBody>
									<div>
										<div className='row' style={{ paddingTop: '1.5rem' }}>
											<CardLabel icon='ListAlt' iconColor='info'>
												<CardTitle>Learning Module</CardTitle>
											</CardLabel>
										</div>
										<Preview setItem={setItemDetail} item={itemDetail} />
										<InfoTraining format={format} item={itemDetail} />
									</div>
								</CardBody>
							</Card>
						</div>
					)}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default TrainingPage;
