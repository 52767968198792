import React from 'react';
import moment from 'moment';
import Button from '../../../components/bootstrap/Button';
import Progress from '../../../components/bootstrap/Progress';
import Badge from '../../../components/bootstrap/Badge';
import useDarkMode from '../../../hooks/useDarkMode';

const InfoTraining = (dt) => {
	const { darkModeStatus } = useDarkMode();
	const { item, format } = dt;
	const trainerList =
		Array.isArray(item?.trainer?.data) && item?.trainer?.data
			? item?.trainer?.data.map((element) => {
					if (item?.trainer?.type === 'internal') {
						return `${element.nik} - ${element.name}`;
					}
					return `${element.name}`;
			  })
			: [`${item?.trainer?.data?.nik} - ${item?.trainer?.data?.name}`];

	return (
		<div className='row my-5'>
			<div className='col-sm-8 col-md-8'>
				<div className='row'>
					<div className='col-sm-6 col-md-6'>
						<table>
							<tr>
								<th style={{ padding: '10px 0' }}>Training Name</th>
								<td>{item?.module_training?.module_name}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Competency</th>
								<td>{item.module_training.competency_name}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Competency Level</th>
								<td>{item.module_training.competency_level}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Date</th>
								<td>
									{item.schedule_training.implementation.type == 'online_platform'
										? `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  } - ${moment(
												item.schedule_training.implementation.end_date,
										  ).format('DD MMMM YYYY')}, ${
												item.schedule_training.implementation.end_time
										  }`
										: `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  }`}
								</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Trainer</th>
								<td>{trainerList.join(', ')}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Status</th>
								<td>{format.Status(item.schedule_training.status)}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Remark</th>
								<td>{item.schedule_training.remark}</td>
							</tr>
							<tr>
								<th style={{ padding: '10px 0' }}>Implementation Type</th>
								<td>
									<Badge isLight={darkModeStatus} rounded='pill' color='success'>
										{item.schedule_training.implementation.type ===
										'online_platform'
											? 'Online'
											: 'Offline'}
									</Badge>
								</td>
							</tr>
						</table>
					</div>
					<div className='col-sm-6 col-md-6'>
						<div className='card-title'>Progress & Certificate</div>
						<div className='mt-4'>
							Complete the progress by completing all the materials, pre-test and
							past-test in this course
						</div>
						<div className='mt-3'>{item.percentage ? item.percentage : 0}%</div>
						<div className='mt-2'>
							<Progress
								value={item.percentage ? item.percentage : 0}
								max={100}
								className='px-0'
							/>
						</div>
						<div className='d-flex flex-row justify-content-start my-3'>
							<div className='col-md-7 d-none'>
								<Button
									icon='Download'
									type='button'
									isDisable
									color='info'
									size='sm'
									onClick={() => {}}>
									Download Certificate
								</Button>
							</div>
							<div className='col-md-4 d-none'>
								<Button
									icon='People'
									type='button'
									isDisable
									color='info'
									size='sm'
									onClick={() => {}}>
									Discussion
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InfoTraining;
