// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable prettier/prettier */
import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training`, payload, {
		headers: await authHeader(),
	});
};

const answer = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training/answer`, payload, {
		headers: await authHeader(),
	});
};

const startTest = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training/start-test`, payload, {
		headers: await authHeader(),
	});
};

const readFile = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training/read-file`, payload, {
		headers: await authHeader(),
	});
};

const startActivity = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training/start-activity`, payload, {
		headers: await authHeader(),
	});
};

const imageTest = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}realization-training/image-test`, payload, {
		headers: await authHeader(),
	});
};

export default {
	create,
	answer,
	startTest,
	readFile,
	startActivity,
	imageTest,
};
