// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars,no-eval,no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import Button from '../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
	CardHeader,
	CardFooter,
} from '../../../components/bootstrap/Card';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import RealizationTrainingModule from '../../../modules/handri/RealizationTrainingModule';
import { isEmptyArray } from '../../../helpers/helpers';
import Textarea from '../../../components/bootstrap/forms/Textarea';

const DynamicInput = (dt) => {
	const { question, indexQuestion, handleAnswer } = dt;

	if (question.type == 'radio_button') {
		return (
			<ChecksGroup style={{ marginLeft: 10 }}>
				{question.answer &&
					question.answer.map((val, index_answer) => {
						return (
							<div className='row mb-3'>
								<Checks
									type='radio'
									id={`answer${indexQuestion + 1}`}
									label={val.answer_name}
									name={`answer${indexQuestion + 1}`}
									value={val.answer_ordering}
									isInline
									checked={question.participant_answer?.answer}
									onChange={(e) => {
										handleAnswer(e.target.value, indexQuestion);
									}}
								/>
							</div>
						);
					})}
			</ChecksGroup>
		);
	}

	if (question.type == 'textarea') {
		return (
			<Textarea
				autoComplete='off'
				onChange={(e) => handleAnswer(e.target.value, indexQuestion)}
			/>
		);
	}

	return <>Not found type</>;
};

const EvaluationFormModal = (dt) => {
	const { showForm, setShowForm, item, test, setItem } = dt;

	const [data, setData] = useState([]);
	const [loadingModal, setLoadingModal] = useState('');
	const [required, setRequired] = useState(false);

	const handleAnswer = (value, indexQuestion) => {
		let question = [];
		data[indexQuestion].participant_answer = {
			answer: value,
			answer_at: new Date(),
		};
		question = [...data];
		setData(question);
		localStorage.setItem('participant_answer', JSON.stringify(question));
		const error = question.every((val) => val.participant_answer !== undefined);
		if (error) setRequired(false);
	};

	const handleFinish = async () => {
		setRequired(false);
		const getItem = JSON.parse(localStorage.getItem('participant_answer'));
		const values = {
			id: item._id,
			question: getItem,
			property_test: test,
		};

		const error = getItem.every((val) => val.participant_answer !== undefined);
		if (error) {
			Swal.fire({
				title: 'Are you sure?',
				text: `Do you want to save this?`,
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Sure',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					// setLoadingModal(true);
					RealizationTrainingModule.answer(values).then((res) => {
						const newRest = { ...res, trainer: res.schedule_training.trainer };
						localStorage.removeItem('participant_answer');
						setData([]);
						setItem(newRest);
						setShowForm(false);
						setLoadingModal(false);
					});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			});
		} else {
			setRequired(true);
		}
	};

	useEffect(() => {
		const question_evaluation = item.evaluation_form.question;

		setData(question_evaluation);
		localStorage.setItem('participant_answer', JSON.stringify(question_evaluation));
	}, [item]);

	return (
		<>
			<Modal isOpen={showForm} size='lg' isCentered setIsOpen={setShowForm} isStaticBackdrop>
				<ModalHeader isOpen={showForm} setIsOpen={setShowForm}>
					<ModalTitle id='Form Input'>Evaluation Form</ModalTitle>
				</ModalHeader>
				<ModalBody style={{ height: 450 }}>
					<div className='row'>
						<div className='d-flex flex-column' style={{ marginLeft: 10 }}>
							<div className='d-flex flex-row mb-3'>
								<div style={{ fontWeight: 500, width: 150 }}>Modul</div>
								<div>{item?.module_training.module_name}</div>
							</div>
							<div className='d-flex flex-row mb-3'>
								<div style={{ fontWeight: 500, width: 150 }}>Training Date</div>
								<div>
									{item.schedule_training.implementation.type == 'online_platform'
										? `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  } - ${moment(
												item.schedule_training.implementation.end_date,
										  ).format('DD MMMM YYYY')}, ${
												item.schedule_training.implementation.end_time
										  }`
										: `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  }`}
								</div>
							</div>
						</div>
						<div className='col-sm-12 col-md-12'>
							<Card shadow='none' borderSize='1' style={{ height: 350 }}>
								<CardBody style={{ maxHeight: 330, overflow: 'auto' }}>
									<table style={{ width: '100%' }}>
										{!isEmptyArray(data) &&
											data.map((question, index_question) => {
												return (
													<>
														<tr
															className={`${
																required
																	? !question.participant_answer
																		? 'bg-l25-danger'
																		: ''
																	: ''
															}`}>
															<td style={{ paddingLeft: 5 }}>{`${
																index_question + 1
															}.`}</td>
															<td style={{ paddingRight: 5 }}>
																<div className='d-flex flex-column'>
																	{question.question}
																</div>
															</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>
																<DynamicInput
																	question={question}
																	handleAnswer={handleAnswer}
																	indexQuestion={index_question}
																/>
															</td>
														</tr>
														<tr>
															<td colSpan='2'>
																<hr />
															</td>
														</tr>
													</>
												);
											})}
									</table>
								</CardBody>
							</Card>
							{required && (
								<div className='text-danger mb-4' style={{ marginLeft: 10 }}>
									Please complete the question
								</div>
							)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						isOutline
						color='info'
						className='float-end'
						onClick={() => {
							setShowForm(false);
						}}>
						Close
					</Button>
					<Button type='submit' color='info' className='float-end' onClick={handleFinish}>
						Finish
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={loadingModal} size='sm' isCentered setIsOpen={() => {}} isStaticBackdrop>
				<ModalBody
					style={{ backgroundColor: '#6c5dd3', color: 'white' }}
					className='text-center'>
					<button className='btn btn-primary' type='button' disabled>
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span className='sr-only'>Loading...</span>
					</button>
				</ModalBody>
			</Modal>
		</>
	);
};

export default EvaluationFormModal;
