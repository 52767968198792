import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import { useFormik } from 'formik';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Card, { CardBody } from '../components/bootstrap/Card';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Input from '../components/bootstrap/forms/Input';
import Button from '../components/bootstrap/Button';
import LogoLNK from '../components/LogoLNK';
import useDarkMode from '../hooks/useDarkMode';
import AuthModule from '../modules/AuthModule';
import showNotification from '../components/extras/showNotification';

const validate = (values) => {
	const errors = {};
	if (!values.username || values.username === '') {
		errors.u = 'Required';
	}
	if (!values.password || values.password === '') {
		errors.p = 'Required';
	}
	return errors;
};
// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const accessToken = localStorage.getItem('accessToken');
const appToken = localStorage.getItem('appToken');
const localName = process.env.REACT_APP_NAME;
const decodeToken = appToken ? jwt_decode(appToken) : null;

const Login = ({ isLoggedIn }) => {
	const [status, setStatus] = useState({
		loading: false,
		isLoggedIn,
		token: null,
		username: '',
		password: '',
	});
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	// eslint-disable-line react/prop-types
	useEffect(() => {
		const fetchLogin = () => {
			if (!status.isLoggedIn && !status.loading && !accessToken) {
				return AuthModule.generateToken();
			}
			const parseAccessToken = accessToken && JSON.parse(accessToken);
			if (parseAccessToken && decodeToken && decodeToken.name === localName) {
				navigate('/home');
			}
			return {};
		};
		fetchLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const handleLogin = (values) => {
		if (values.username && values.password) {
			status.loading = true;
			setStatus(status);
			AuthModule.login(values.username.toUpperCase(), values.password)
				.then(async (res) => {
					if (res.accessToken) {
						const verifyToken = jwt_decode(res.accessToken);
						localStorage.setItem('accessToken', JSON.stringify(res));
						localStorage.setItem('photo', JSON.stringify({ ...res?.photo }));
						let role = 'guest user';
						if (verifyToken.details.hris_org_tree.current_person) {
							role = `${verifyToken.details.hris_org_tree.current_person.nama_department} ${verifyToken.details.hris_org_tree.current_person.nama_posisi}`;
							// get menu
							await AuthModule.getMenu(role).then(() => {
								window.location.reload();
							});
						}
					}

					status.loading = false;
					setStatus(status);
				})
				.catch((err) => {
					status.loading = false;
					setStatus(status);
					showNotification('Warning!', err, 'danger');
				});
		}
	};
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleLogin(values);
		},
	});
	return (
		<PageWrapper title='Login' className={classNames('bg-info')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							stretch
							tag='form'
							noValidate
							onSubmit={formik.handleSubmit}>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLNK width={200} />
									</Link>
								</div>
								<LoginHeader />
								<div className='col-12 mb-3'>
									<FormGroup
										id='username'
										autoComplete='off'
										isFloating
										label='Your email or username'>
										<Input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='username'
											className='uppercase'
											isValid={formik.isValid}
											invalidFeedback={formik.errors.u}
											value={formik.values.username}
											isTouched={formik.touched.username}
											readOnly={status.loading}
										/>
									</FormGroup>
								</div>
								<div className='col-12 mb-3'>
									<FormGroup
										id='password'
										isFloating
										autoComplete='off'
										label='Password'>
										<Input
											type='password'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='password'
											isValid={formik.isValid}
											invalidFeedback={formik.errors.p}
											value={formik.values.password}
											isTouched={formik.touched.password}
											readOnly={status.loading}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<Button type='submit' color='warning' className='w-100 py-3'>
										Login
									</Button>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Login.propTypes = {
	isLoggedIn: PropTypes.bool,
};
Login.defaultProps = {
	isLoggedIn: false,
};

export default Login;
