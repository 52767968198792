// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars,no-eval,no-nested-ternary */
import React from 'react';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Accordion, { AccordionItem } from '../../../components/bootstrap/Accordion';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import TestModal from './TestModal';
import { isEmptyArray } from '../../../helpers/helpers';
import Spinner from '../../../components/bootstrap/Spinner';
import EvaluationFormModal from './EvaluationFormModal';

const ListChapter = (dt) => {
	const {
		item,
		setItem,
		setShowForm,
		showForm,
		setShowFormEvaluation,
		showFormEvaluation,
		loading,
		handleButtonStart,
		test,
		setUniqueButton,
		uniqueButton,
	} = dt;

	return (
		<div className='col-sm-4 col-md-4'>
			<Card borderSize={1} shadow='none' style={{ height: 450, overflow: 'auto' }}>
				<CardBody>
					{!isEmptyArray(item.pretest.question) && (
						<div className='d-flex flex-row align-items-center my-3'>
							<div className='col-md-9 d-flex align-items-center justify-content-start'>
								<Icon
									icon='FactCheck'
									color='info'
									style={{ marginRight: 5 }}
									size='4x'
								/>
								<div>
									<div style={{ fontWeight: '600' }}>Pre Test</div>
									<div className='text-muted mt-1'>
										Score :{' '}
										{item.pretest.score == null ? '-' : item.pretest.score}
									</div>
								</div>
								{item.pretest.finish_activity && (
									<Icon
										icon='CheckCircleOutline'
										color='success'
										style={{ marginLeft: 15 }}
										size='lg'
									/>
								)}
							</div>
							<div className='col-md-2'>
								<Button
									type='button'
									className='d-flex flex-row align-items-center'
									color='info'
									isDisable={
										loading
											? true
											: item.schedule_training.status === 'expired' &&
											  item.pretest.activity == null
											? true
											: item.pretest.is_disable
									}
									onClick={() => {
										handleButtonStart('pretest');
										setUniqueButton('pretest');
									}}>
									{loading && uniqueButton == 'pretest' && (
										<Spinner color='light' inButton isSmall>
											Loading...
										</Spinner>
									)}
									Start
								</Button>
							</div>
						</div>
					)}
					<Accordion id='accordion-form' activeItemId={false}>
						{item.module_training.chapter &&
							item.module_training.chapter.map((val, chapterIndex) => {
								return (
									<AccordionItem
										key={`chapter - ${chapterIndex + 1}`}
										id={`chapter-${chapterIndex + 1}`}
										title={
											<div className='d-flex flex-column'>
												<div style={{ fontWeight: 600 }}>
													{val.chapter_name}
												</div>
												<div
													className='text-muted mt-1'
													style={{ fontWeight: 400, fontSize: 12 }}>
													{val.count_material} Materials
												</div>
											</div>
										}>
										{val.content &&
											val.content.map((val_content, contentIndex) => {
												return (
													<div className='d-flex flex-row align-items-center my-3'>
														<div className='col-md-10 d-flex align-items-center justify-content-start'>
															<Icon
																icon='FactCheck'
																color='info'
																style={{
																	marginRight: 5,
																}}
																size='3x'
															/>
															<div style={{ maxWidth: '100px' }}>
																<div
																	style={{
																		fontWeight: '600',
																	}}>
																	{val_content.type === 'material'
																		? val_content.material_type
																		: val_content.type ===
																				'test' &&
																		  !val_content.is_remedial
																		? 'Post Test'
																		: 'Remedial'}
																</div>
																<div
																	className={`${
																		val_content.type ===
																		'material'
																			? 'd-none'
																			: ''
																	} text-muted`}>
																	Score :{' '}
																	{val_content.score == null
																		? '-'
																		: val_content.score}
																</div>
															</div>
															{val_content.start_activity && (
																<Icon
																	icon='CheckCircleOutline'
																	color='success'
																	style={{ marginLeft: 15 }}
																	size='lg'
																/>
															)}
														</div>
														<div className='col-md-2'>
															<Button
																type='button'
																className='d-flex flex-row align-items-center'
																color='info'
																size='sm'
																isDisable={
																	loading
																		? true
																		: item.schedule_training
																				.status ===
																				'expired' &&
																		  val_content.activity ==
																				null
																		? true
																		: val_content.is_disable
																}
																onClick={() => {
																	handleButtonStart(
																		val_content.type ===
																			'material'
																			? 'material'
																			: val_content.type ===
																					'test' &&
																			  !val_content.is_remedial
																			? 'posttest'
																			: 'remedial',
																		chapterIndex,
																		contentIndex,
																		val_content,
																	);
																	setUniqueButton(
																		`${
																			val_content.type ===
																			'material'
																				? 'material'
																				: val_content.type ===
																						'test' &&
																				  !val_content.is_remedial
																				? 'posttest'
																				: 'remedial'
																		}${chapterIndex}${contentIndex}`,
																	);
																}}>
																{loading &&
																	uniqueButton ==
																		`${
																			val_content.type ===
																			'material'
																				? 'material'
																				: val_content.type ===
																						'test' &&
																				  !val_content.is_remedial
																				? 'posttest'
																				: 'remedial'
																		}${chapterIndex}${contentIndex}` && (
																		<Spinner
																			color='light'
																			inButton
																			isSmall>
																			Loading...
																		</Spinner>
																	)}
																Start
															</Button>
														</div>
													</div>
												);
											})}
									</AccordionItem>
								);
							})}
					</Accordion>
					{!isEmptyArray(item.posttest_offline.question) && (
						<div className='d-flex flex-row align-items-center my-3'>
							<div className='col-md-9 d-flex align-items-center justify-content-start'>
								<Icon
									icon='FactCheck'
									color='info'
									style={{ marginRight: 5 }}
									size='4x'
								/>
								<div>
									<div style={{ fontWeight: '600' }}>Post Test</div>
									<div className='text-muted mt-1'>
										Score :{' '}
										{item.posttest_offline.score == null
											? '-'
											: item.posttest_offline.score}
									</div>
									{item.posttest_offline.finish_activity && (
										<Icon
											icon='CheckCircleOutline'
											color='success'
											style={{ marginLeft: 15 }}
											size='lg'
										/>
									)}
								</div>
							</div>
							<div className='col-md-2'>
								<Button
									type='button'
									className='d-flex flex-row align-items-center'
									color='info'
									isDisable={
										loading
											? true
											: item.schedule_training.status === 'expired' &&
											  item.posttest_offline.activity == null
											? true
											: item.posttest_offline.is_disable
									}
									onClick={() => {
										handleButtonStart('posttest_offline');
										setUniqueButton('posttest_offline');
									}}>
									{loading && uniqueButton === 'posttest_offline' && (
										<Spinner color='light' inButton isSmall>
											Loading...
										</Spinner>
									)}
									Start
								</Button>
							</div>
						</div>
					)}
					{!isEmptyArray(item.evaluation_form.question) && (
						<div className='d-flex flex-row align-items-center my-3'>
							<div className='col-md-9 d-flex align-items-center justify-content-start'>
								<Icon
									icon='FactCheck'
									color='info'
									style={{ marginRight: 5 }}
									size='4x'
								/>
								<div>
									<div style={{ fontWeight: '600' }}>Evaluation Form</div>
								</div>
								{item.evaluation_form.finish_activity && (
									<Icon
										icon='CheckCircleOutline'
										color='success'
										style={{ marginLeft: 15 }}
										size='lg'
									/>
								)}
							</div>
							<div className='col-md-2'>
								<Button
									type='button'
									className='d-flex flex-row align-items-center'
									color='info'
									isDisable={
										loading
											? true
											: item.schedule_training.status === 'expired' &&
											  item.evaluation_form.activity == null
											? true
											: item.evaluation_form.is_disable
									}
									onClick={() => {
										handleButtonStart('evaluation_form');
										setUniqueButton('evaluation_form');
									}}>
									{loading && uniqueButton == 'evaluation_form' && (
										<Spinner color='light' inButton isSmall>
											Loading...
										</Spinner>
									)}
									Start
								</Button>
							</div>
						</div>
					)}
				</CardBody>
			</Card>
			<TestModal
				showForm={showForm}
				setShowForm={setShowForm}
				item={item}
				test={test}
				setItem={setItem}
			/>
			<EvaluationFormModal
				showForm={showFormEvaluation}
				setShowForm={setShowFormEvaluation}
				item={item}
				test={test}
				setItem={setItem}
			/>
		</div>
	);
};

export default ListChapter;
