// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars,no-eval,no-nested-ternary */
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import { pdfjs } from 'react-pdf';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { isEmptyArray, isEmptyObject } from '../../../helpers/helpers';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WelcomePreview = (dt) => {
	const { lastFlow, item, handleButtonStart, loading, uniqueButton, setUniqueButton } = dt;

	const [label, setLabel] = useState({
		heading_1: '',
		heading_2: '',
		text_button: '',
	});

	const viewLabel = (content) => {
		if (content?.type === 'pretest') {
			setLabel({
				heading_1: 'Pre Test',
				heading_2: "Let's do pre-test before we start learning",
				text_button: 'Start Pre Test',
			});
		} else if (content?.type === 'posttest_offline') {
			setLabel({
				heading_1: 'Post Test',
				heading_2: "Let's do post-test before we start learning",
				text_button: 'Start Post Test',
			});
		} else if (content?.type === 'material') {
			setLabel({
				heading_1: 'Start Learning',
				heading_2: "Let's start learning",
				text_button: 'Start Learning',
			});
		} else if (content?.type === 'test' && !content?.content_is_remedial) {
			setLabel({
				heading_1: 'Post Test',
				heading_2: "Let's do post-test to make sure you master the material",
				text_button: 'Start Post Test',
			});
		} else if (content?.type === 'test' && content?.content_is_remedial) {
			setLabel({
				heading_1: 'Remedial',
				heading_2: "Let's do remedial",
				text_button: 'Start Remedial',
			});
		} else if (content?.type === 'evaluation_form') {
			setLabel({
				heading_1: 'Evaluation Form',
				heading_2: 'Please fill out the evaluation form, as our evaluation material',
				text_button: 'Start Filling',
			});
		} else if (content === null) {
			setLabel({
				heading_1: 'Completed',
				heading_2: 'You have completed this training modul!',
			});
		}
	};

	useEffect(() => {
		viewLabel(lastFlow);
	}, [lastFlow]);

	return (
		<div className='row d-flex align-items-center' style={{ height: 400 }}>
			<div className='col-md-6'>
				<img src='/images/person_1.png' alt='welcome' style={{ width: '100%' }} />
			</div>
			<div
				className={`col-md-6 d-flex align-items-center flex-column justify-start ${
					lastFlow != null && isEmptyObject(lastFlow) ? 'd-none' : ''
				}`}>
				{item.schedule_training.status === 'expired' && (
					<>
						{' '}
						<div style={{ width: '100%', fontWeight: 500 }} className='mb-3'>
							Missed
						</div>
						<div
							style={{ width: '100%', fontWeight: 700, fontSize: 16 }}
							className='mb-3'>
							You did not complete this training module!
						</div>
					</>
				)}
				{item.schedule_training.status !== 'expired' && (
					<>
						{' '}
						<div style={{ width: '100%', fontWeight: 500 }} className='mb-3'>
							{label.heading_1}
						</div>
						<div
							style={{ width: '100%', fontWeight: 700, fontSize: 16 }}
							className='mb-3'>
							{label.heading_2}
						</div>
						{label.text_button && (
							<Button
								type='submit'
								className='d-flex flex-row align-items-center align-self-start'
								color='info'
								onClick={() => {
									if (lastFlow?.type === 'pretest') {
										handleButtonStart('pretest');
										setUniqueButton('preview');
									} else if (lastFlow?.type === 'posttest_offline') {
										handleButtonStart('posttest_offline');
										setUniqueButton('preview');
									} else if (lastFlow?.type === 'material') {
										handleButtonStart(
											'material',
											lastFlow?.chapter_index,
											lastFlow?.content_index,
											item.module_training.chapter[lastFlow.chapter_index]
												.content[lastFlow.content_index],
										);
										setUniqueButton('preview');
									} else if (lastFlow?.type === 'test') {
										handleButtonStart(
											'posttest',
											lastFlow?.chapter_index,
											lastFlow?.content_index,
											item.module_training.chapter[lastFlow.chapter_index]
												.content[lastFlow.content_index],
										);
										setUniqueButton('preview');
									} else if (lastFlow?.type === 'remedial') {
										handleButtonStart(
											'remedial',
											lastFlow?.chapter_index,
											lastFlow?.content_index,
											item.module_training.chapter[lastFlow.chapter_index]
												.content[lastFlow.content_index],
										);
										setUniqueButton('preview');
									} else if (lastFlow?.type === 'evaluation_form') {
										handleButtonStart('evaluation_form');
										setUniqueButton('preview');
									}
								}}>
								{loading && uniqueButton === 'preview' && (
									<Spinner color='light' inButton isSmall>
										Loading...
									</Spinner>
								)}
								{label.text_button}
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const MaterialsPreview = (dt) => {
	const {
		material,
		refPlayVideo,
		flow,
		handleButtonStart,
		item,
		loading,
		uniqueButton,
		setUniqueButton,
	} = dt;
	const [lastFlow, setLastFlow] = useState(null);

	const [pageNumMaterial, setPageNumMaterial] = useState(1);
	const [pageSumMaterial, setPageSumMaterial] = useState(0);
	const [pdfMaterial, setPdfMaterial] = useState(null);
	const [loadingMaterial, setLoadingMaterial] = useState(false);

	const onPreviousPageMaterial = () => {
		if (pageNumMaterial <= 1) {
			return;
		}
		renderPage(pageNumMaterial - 1, null, 'material');
	};

	const onNextPageMaterial = () => {
		console.log(pageNumMaterial, pdfMaterial.numPages);
		if (pageNumMaterial >= pdfMaterial.numPages) {
			return;
		}
		renderPage(pageNumMaterial + 1, null, 'material');
	};

	const renderPage = (pageNums, objDoc, type) => {
		console.log(pageNums, 'page-nums-render-page');
		setPageNumMaterial(pageNums);
		if (objDoc) {
			objDoc.getPage(pageNums).then((page) => {
				const canvas = document.getElementById(`the-canvas-${type}`);
				const context = canvas.getContext('2d');

				const viewport = page.getViewport({ scale: 0.45 });
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				const renderContext = {
					canvasContext: context,
					viewport,
				};

				page.render(renderContext);
			});
		} else {
			pdfMaterial.getPage(pageNums).then((page) => {
				const canvas = document.getElementById(`the-canvas-${type}`);
				const context = canvas.getContext('2d');

				const viewport = page.getViewport({ scale: 0.45 });
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				const renderContext = {
					canvasContext: context,
					viewport,
				};

				page.render(renderContext);
			});
		}
	};

	useEffect(() => {
		setPageSumMaterial(0);
		setPageNumMaterial(1);
		if (material?.pdf_file_embed) {
			setPdfMaterial(null);
			pdfjs
				.getDocument(
					`data:${material?.pdf_file_embed.mimetype};base64,${material?.pdf_file_embed.file}`,
				)
				.promise.then((pdf) => {
					console.log(pdf.numPages, 'numpages');
					setPageSumMaterial(pdf.numPages);
					setPdfMaterial(pdf);
					renderPage(pageNumMaterial, pdf, 'material');
				});
		} else {
			setLoadingMaterial(false);
		}
		return () => {};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material?.pdf_file_embed]);
	useEffect(() => {
		let position = [];
		if (isEmptyArray(flow)) {
			setLastFlow({});
		} else if (!isEmptyArray(flow)) {
			if (item.schedule_training.implementation.type === 'online_platform') {
				position = flow?.filter((val) => val.activity === null && val.flow === false);
				setLastFlow(!isEmptyArray(position) ? position[position.length - 1] : null);
			} else if (item.schedule_training.implementation.type === 'offline') {
				position = flow?.filter(
					(val) => val.activity === null && val.flow === false && val.type !== 'material',
				);
				setLastFlow(!isEmptyArray(position) ? position[position.length - 1] : null);
			}
		}
	}, [flow, item]);

	return (
		<div className='col-sm-8 col-md-8'>
			<Card borderSize={1} shadow='none' style={{ height: 450 }}>
				<CardBody style={{ maxHeight: 400, overflow: `${material ? 'auto' : ''}` }}>
					<div className={`col-md-12 ${!material ? 'd-none' : ''}`}>
						{material && material?.video_embed_code !== '' && (
							<div className='mb-5'>
								<div className='mb-5' style={{ width: '100%' }}>
									{parse(decode(material?.video_embed_code))}
								</div>
							</div>
						)}
						{material && material?.video_url !== '' && (
							<div className='mb-5'>
								<ReactPlayer
									controls
									ref={refPlayVideo}
									url={material?.video_url}
								/>
							</div>
						)}
						{material && material?.text !== '' && (
							<div className='m-3'>{material?.text}</div>
						)}
						{material?.pdf_file_embed && (
							<>
								<div className='mb-2 d-flex flex-row justify-content-between align-items-center'>
									<ButtonGroup size='sm'>
										<Button
											color='primary'
											id='prev'
											type='button'
											isOutline
											onClick={() => {
												onPreviousPageMaterial();
											}}>
											Previous
										</Button>
										<Button
											color='primary'
											id='next'
											type='button'
											onClick={() => {
												onNextPageMaterial();
											}}>
											Next
										</Button>
									</ButtonGroup>
									<div className='d-flex justify-content-center align-items-center'>
										Page: {pageNumMaterial} / {pageSumMaterial}
									</div>
								</div>

								<div className='row'>
									<div
										style={{
											textAlign: 'center',
											height: `${window.innerHeight - 190}px`,
											overflow: 'scroll',
											border: '1px solid black',
										}}>
										<canvas id='the-canvas-material' width='100%' />
									</div>
								</div>
							</>
						)}
					</div>
					<div className={`col-md-12 ${material ? 'd-none' : ''}`}>
						{!material && (
							<WelcomePreview
								lastFlow={lastFlow}
								handleButtonStart={handleButtonStart}
								item={item}
								loading={loading}
								uniqueButton={uniqueButton}
								setUniqueButton={setUniqueButton}
							/>
						)}
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default MaterialsPreview;
