// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars,no-eval,no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import Button from '../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
	CardHeader,
	CardFooter,
} from '../../../components/bootstrap/Card';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import RealizationTrainingModule from '../../../modules/handri/RealizationTrainingModule';

const TestModal = (dt) => {
	const { showForm, setShowForm, item, test, setItem } = dt;

	const [numberingActive, setNumberingActive] = useState(1);
	const [questionActive, setQuestionActive] = useState(null);
	const [data, setData] = useState([]);
	const [title, setTitle] = useState('');
	const [loadingModal, setLoadingModal] = useState('');
	const [required, setRequired] = useState(false);

	const handleNumbering = (index, type) => {
		let newIndex = index;
		if (type !== undefined && type == 'prev') {
			newIndex -= 1;
		} else if (type !== undefined && type == 'next') {
			newIndex += 1;
		} else {
			newIndex += 1;
		}

		if (index !== numberingActive - 1) {
			const page = newIndex;
			setNumberingActive(page);
			setQuestionActive(data[page]);
		}
	};

	const handleAnswer = (value) => {
		let question = [];
		data[numberingActive - 1].participant_answer = {
			answer: value,
			answer_at: new Date(),
		};

		question = [...data];

		setData(question);

		const error = question.every((val) => val.participant_answer !== undefined);
		if (error) setRequired(false);
	};

	const handleFinish = async () => {
		setRequired(false);

		const values = {
			id: item._id,
			question: data,
			property_test: test,
		};

		const error = data.every((val) => val.participant_answer !== undefined);

		if (error) {
			Swal.fire({
				title: 'Are you sure?',
				text: `Do you want to save this?`,
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: 'No, Cancel',
				confirmButtonText: 'Yes, Sure',

				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					setLoadingModal(true);

					RealizationTrainingModule.answer(values).then((res) => {
						const newRest = { ...res, trainer: res.schedule_training.trainer };
						setNumberingActive(1);
						setQuestionActive(null);
						setData([]);
						setTitle('');
						setItem(newRest);
						setShowForm(false);
						setLoadingModal(false);
					});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
				}
			});
		} else {
			setRequired(true);
		}
	};

	useEffect(() => {
		let question = [];
		if (test.type_test === 'pretest') {
			question = item.pretest.question;
			setTitle('Pre Test');
		} else if (test.type_test === 'evaluation_form') {
			question = item.evaluation_form.question;
			setTitle('Evaluation Form');
		} else if (test.type_test === 'posttest_offline') {
			question = item.posttest_offline.question;
			setTitle('Post Test');
		} else if (test.type_test === 'posttest' || test.type_test === 'remedial') {
			question =
				item.module_training.chapter[test.chapter_index].content[test.content_index]
					.question;
			setTitle(test.type_test === 'posttest' ? 'Post Test' : 'Remedial');
		}

		setData(question);
	}, [item, test]);

	useEffect(() => {
		if (data) {
			setQuestionActive(data[numberingActive - 1]);
		}
	}, [data, numberingActive]);

	return (
		<>
			<Modal isOpen={showForm} fullScreen isCentered setIsOpen={setShowForm} isStaticBackdrop>
				<ModalHeader isOpen={showForm} setIsOpen={setShowForm}>
					<ModalTitle id='Form Input'>{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='d-flex flex-column' style={{ marginLeft: 10 }}>
							<div className='d-flex flex-row mb-3'>
								<div style={{ fontWeight: 500, width: 150 }}>Modul</div>
								<div>{item?.module_training.module_name}</div>
							</div>
							<div className='d-flex flex-row mb-3'>
								<div style={{ fontWeight: 500, width: 150 }}>Training Date</div>
								<div>
									{item.schedule_training.implementation.type == 'online_platform'
										? `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  } - ${moment(
												item.schedule_training.implementation.end_date,
										  ).format('DD MMMM YYYY')}, ${
												item.schedule_training.implementation.end_time
										  }`
										: `${moment(
												item.schedule_training.implementation.start_date,
										  ).format('DD MMMM YYYY')},  ${
												item.schedule_training.implementation.start_time
										  }`}
								</div>
							</div>
						</div>
						<div className='col-sm-8 col-md-8'>
							<Card>
								<CardHeader>
									<CardLabel icon='ListAlt'>
										<CardTitle>Question {numberingActive}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='d-flex flex-column'>
										{questionActive?.question_name}
										{questionActive?.question_file != '' ? (
											<>
												<br />
												<img
													src={`data:${questionActive?.question_image?.mimetype};base64,${questionActive?.question_image?.file}`}
													alt='img-1'
													width={150}
													className='mt-3'
												/>
											</>
										) : (
											''
										)}{' '}
									</div>
									<hr />
									<ChecksGroup style={{ marginLeft: 10 }}>
										{questionActive?.answer &&
											questionActive.answer.map((val, index) => {
												return (
													<div className='row mb-3'>
														<Checks
															type='radio'
															id='answer'
															label={
																<>
																	{val.answer_name}
																	<br />
																	{val.answer_file != '' ? (
																		<img
																			src={`data:${val.answer_image?.mimetype};base64,${val.answer_image?.file}`}
																			alt='img-1'
																			width={150}
																			className='mt-2'
																		/>
																	) : (
																		''
																	)}
																</>
															}
															name='answer'
															value={val.answer_ordering}
															isInline
															checked={
																questionActive.participant_answer
																	?.answer
															}
															onChange={(e) => {
																handleAnswer(e.target.value, index);
															}}
														/>
													</div>
												);
											})}
									</ChecksGroup>
								</CardBody>
								<CardFooter>
									<Button
										type='button'
										color={numberingActive === 1 ? 'light' : 'info'}
										isDisable={numberingActive === 1}
										onClick={() => {
											handleNumbering(numberingActive, 'prev');
										}}>
										Previous Question
									</Button>
									<Button
										type='button'
										color={numberingActive === data.length ? 'light' : 'info'}
										isDisable={numberingActive === data.length}
										onClick={() => {
											handleNumbering(numberingActive, 'next');
										}}>
										Next Question
									</Button>
								</CardFooter>
							</Card>
						</div>
						<div className='col-sm-4 col-md-4'>
							<Card>
								<CardHeader>
									<CardLabel icon='ListAlt'>
										<CardTitle>Question Number</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									{data &&
										data.map((v, i) => {
											return (
												<Button
													type='submit'
													className='mx-2 mb-2'
													isOutline={
														required
															? !v.participant_answer
																? required
																: false
															: i === numberingActive - 1
													}
													color={
														!v.participant_answer
															? required
																? 'danger'
																: 'light'
															: 'success'
													}
													size='sm'
													onClick={() => {
														handleNumbering(i);
													}}>
													{i + 1}
												</Button>
											);
										})}
									{required && (
										<div className='text-danger mt-4'>
											Please complete the question
										</div>
									)}
								</CardBody>
								<CardFooter>
									<div className='d-flex flex-row'>
										<div
											style={{
												width: 20,
												height: 20,
												borderRadius: 3,
												marginRight: 10,
											}}
											className='bg-success'>
											&nbsp;
										</div>
										already answered
										<div
											style={{
												width: 20,
												height: 20,
												borderRadius: 3,
												marginLeft: 15,
												marginRight: 10,
											}}
											className='bg-light'>
											&nbsp;
										</div>
										not answered
									</div>
								</CardFooter>
							</Card>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						isOutline
						color='info'
						className='float-end'
						onClick={() => {
							setShowForm(false);
						}}>
						Close
					</Button>
					<Button type='submit' color='info' className='float-end' onClick={handleFinish}>
						Finish
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={loadingModal} size='sm' isCentered setIsOpen={() => {}} isStaticBackdrop>
				<ModalBody
					style={{ backgroundColor: '#6c5dd3', color: 'white' }}
					className='text-center'>
					<button className='btn btn-primary' type='button' disabled>
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span className='sr-only'>Loading...</span>
					</button>
				</ModalBody>
			</Modal>
		</>
	);
};

export default TestModal;
