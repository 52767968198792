// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars,no-eval,no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import MaterialsPreview from './MaterialsPreview';
import ListChapter from './ListChapter';
import RealizationTrainingModule from '../../../modules/handri/RealizationTrainingModule';

const Preview = (dt) => {
	const { setItem, item } = dt;
	const [material, setMaterial] = useState(null);
	const [flow, setFlow] = useState(null);
	const refPlayVideo = useRef(null);
	const [test, setTest] = useState({
		type_test: null,
		chapter_index: null,
		content_index: null,
	});
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showFormEvaluation, setShowFormEvaluation] = useState(false);
	const [uniqueButton, setUniqueButton] = useState(null);

	const handleButtonStart = (type, chapterIndex, contentIndex, content) => {
		if (type == 'material') {
			if (content.pdf_file != '') {
				setLoading(true);
				const values = {};
				values.pdf_file = content.pdf_file;
				values.video_embed_code = content.video_embed_code;
				values.id = item._id;
				values.property_file = {
					chapter_index: chapterIndex,
					content_index: contentIndex,
				};
				RealizationTrainingModule.readFile(values).then((res) => {
					setLoading(false);
					const newRest = { ...res.item, trainer: res.item.schedule_training.trainer };
					res.video_url = content.video_url;
					res.text = content.text;
					res.video_embed_code = content.video_embed_code;
					setMaterial(res);
					setFlow(res.flow);
					setItem(newRest);
				});
			} else {
				setLoading(true);
				const values = {};
				values.id = item._id;
				values.property_file = {
					chapter_index: chapterIndex,
					content_index: contentIndex,
				};
				RealizationTrainingModule.startActivity(values).then((res) => {
					const newRest = { ...res, trainer: res.schedule_training.trainer };
					setLoading(false);
					setItem(newRest);
					setMaterial(content);
					setFlow(res.flow);
				});
			}
		} else {
			const content_test =
				type === 'posttest' || type === 'remedial'
					? item.module_training.chapter[chapterIndex].content[contentIndex]
					: null;
			const start_activity =
				type === 'pretest'
					? item.pretest.start_activity
					: type === 'evaluation_form'
					? item.evaluation_form.start_activity
					: type === 'posttest_offline'
					? item.posttest_offline.start_activity
					: content_test.start_activity;

			if (!start_activity) {
				Swal.fire({
					title: 'Are you sure?',
					text: `Do you want to start the ${
						type === 'posttest'
							? 'Post Test'
							: type === 'evaluation_form'
							? 'Evaluation Form'
							: type === 'remedial'
							? 'Remedial'
							: type === 'posttest_offline'
							? 'Post Test'
							: 'Pre Test'
					}?`,
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: 'No, Cancel',
					confirmButtonText: 'Yes, Sure',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						setLoading(true);
						const values = {
							id: item._id,
							type_test: type,
							chapter_index: chapterIndex,
							content_index: contentIndex,
						};
						RealizationTrainingModule.startTest(values).then((res) => {
							const newRest = { ...res, trainer: res.schedule_training.trainer };
							setItem(newRest);
							if (type === 'pretest' || type === 'posttest_offline') {
								setShowForm(true);
								setTest({
									type_test: type,
								});
							} else if (type === 'posttest' || type === 'remedial') {
								setShowForm(true);
								setTest({
									type_test: type,
									chapter_index: chapterIndex,
									content_index: contentIndex,
								});
							} else if (type === 'evaluation_form') {
								setShowFormEvaluation(true);
								setTest({
									type_test: type,
								});
							}
							setLoading(false);
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) {
					}
				});
			} else {
				setLoading(true);
				const values = {};
				if (type === 'pretest') {
					values.question = item.pretest.question;
				} else if (type === 'posttest_offline') {
					values.question = item.posttest_offline.question;
				} else if (type === 'posttest' || type === 'remedial') {
					values.question =
						item.module_training.chapter[chapterIndex].content[contentIndex].question;
				}

				if (type !== 'evaluation_form') {
					RealizationTrainingModule.imageTest(values).then((res) => {
						setLoading(false);
						if (type === 'pretest') {
							item.pretest.question = [...res];
							setShowForm(true);
						} else if (type === 'posttest_offline') {
							item.posttest_offline.question = [...res];
							setShowForm(true);
						} else if (type === 'posttest' || type === 'remedial') {
							item.module_training.chapter[chapterIndex].content[
								contentIndex
							].question = [...res];
							setShowForm(true);
						}
						setTest({
							type_test: type,
							chapter_index: chapterIndex,
							content_index: contentIndex,
						});
					});
				} else if (type === 'evaluation_form') {
					setLoading(false);
					setTest({
						type_test: type,
						chapter_index: chapterIndex,
						content_index: contentIndex,
					});
					setShowFormEvaluation(true);
				}
			}
		}
	};

	useEffect(() => {
		setFlow(item.flow);
	}, [item]);

	return (
		<div className='row my-5'>
			<MaterialsPreview
				material={material}
				refPlayVideo={refPlayVideo}
				flow={flow}
				item={item}
				handleButtonStart={handleButtonStart}
				loading={loading}
				setUniqueButton={setUniqueButton}
				uniqueButton={uniqueButton}
			/>
			<ListChapter
				item={item}
				setItem={setItem}
				setMaterial={setMaterial}
				setFlow={setFlow}
				refPlayVideo={refPlayVideo}
				handleButtonStart={handleButtonStart}
				loading={loading}
				test={test}
				setShowForm={setShowForm}
				showForm={showForm}
				setShowFormEvaluation={setShowFormEvaluation}
				showFormEvaluation={showFormEvaluation}
				setUniqueButton={setUniqueButton}
				uniqueButton={uniqueButton}
			/>
		</div>
	);
};

export default Preview;
