import React, { useState } from 'react';
import { useMeasure } from 'react-use';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import Icon from '../../components/icon/Icon';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '../../components/bootstrap/Modal';
import Spinner from '../../components/bootstrap/Spinner';
import Button from '../../components/bootstrap/Button';
import Label from '../../components/bootstrap/forms/Label';
import showNotification from '../../components/extras/showNotification';
import ComplainFormModule from '../../modules/sodiq/ComplainFormModule';
import { getRequester } from '../../helpers/helpers';

const Footer = () => {
	const [ref, { height }] = useMeasure();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState(null);
	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);
	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			['link', 'image'],
			['clean'],
		],
	};

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
	];

	const handleOpenComplain = () => {
		setDescription(null);
		setIsOpen(true);
	};

	const handleSubmit = () => {
		if (description) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Please check your entries !',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.value) {
					setLoading(true);
					const { username, person_name } = getRequester();
					const payload = {
						username,
						name: person_name,
						url: window.location.href,
						description,
					};
					ComplainFormModule.create(payload)
						.then(() => {
							showNotification(
								'Success!',
								'Data has been saved Successfully',
								'success',
							);
							setIsOpen(false);
							setLoading(false);
							setDescription(null);
						})
						.catch((err) => {
							showNotification('Warning!', err, 'danger');
							setLoading(false);
						});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire('Cancelled', 'Your data is safe :)', 'error');
				}
			});
		} else {
			showNotification('Warning!', 'Please Entries Description', 'danger');
		}
	};

	return (
		<>
			<footer ref={ref} className='footer'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col'>
							<span className='fw-light'>
								Copyright © 2022 - Version 2.2.2
								<span
									aria-hidden='true'
									style={{
										cursor: 'pointer',
										color: '#4d69fa',
										marginLeft: '10px',
										textDecoration: 'underline',
									}}
									onClick={() => handleOpenComplain()}>
									<Icon icon='Bug' size='lg' color='info' /> Report bug
								</span>
							</span>
						</div>
					</div>
				</div>
			</footer>
			<Modal
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='lg'
				titleId='modal-input'
				isScrollable
				isCentered>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id='modal-complain'>Report Bug Form</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row' style={{ marginTop: '-25px' }}>
						<div className='col-12 mt-3'>
							<Label style={{ marginBottom: '-5px' }}>Description</Label>
							<div className='text-editor'>
								<ReactQuill
									theme='snow'
									modules={modules}
									formats={formats}
									value={description}
									onChange={setDescription}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<div className='col-md-12 ' style={{ display: !loading ? 'block' : 'none' }}>
						<Button
							style={{ marginLeft: '5px' }}
							onClick={() => {
								setIsOpen(false);
							}}
							icon='Cancel'
							type='button'
							color='warning'
							className='float-start'>
							Cancel
						</Button>
						<Button
							onClick={() => handleSubmit()}
							icon='Save'
							type='button'
							color='success'
							className='float-end'>
							Submit
						</Button>
					</div>
					<div className='col-md-12' style={{ display: loading ? 'block' : 'none' }}>
						<Label
							style={{ marginLeft: '5px', marginTop: '5px' }}
							className='float-end'>
							Processing saved data...
						</Label>
						<Spinner className='float-end' color='success' role='status' size='md' />
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Footer;
